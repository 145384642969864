import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const UserPage = lazy(() =>
	import("./user/UserPage")
);

const LocalPage = lazy(() =>
	import("./local/LocalPage")
);

const SetorPage = lazy(() =>
	import("./setor/SetorPage")
);

const ViaPage = lazy(() =>
	import("./via/ViaPage")
);

const EstabelecimentoPage = lazy(() =>
	import("./estabelecimento/EstabelecimentoPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/user" component={UserPage} />
				<Route path="/local" component={LocalPage} />
				<Route path="/setor" component={SetorPage} />
				<Route path="/via" component={ViaPage} />
				<Route path="/estabelecimento" component={EstabelecimentoPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
