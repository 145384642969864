export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Usuários",
				root: true,
				icon: "fas fa-users",
				page: "user",
				bullet: "dot"
			},
			{
				title: "Locais",
				root: true,
				icon: "fas fa-map-marker-alt",
				page: "local",
				bullet: "dot"
			},
			{
				title: "Setores",
				root: true,
				icon: "fas fa-hiking",
				page: "setor",
				bullet: "dot"
			},
			{
				title: "Vias",
				root: true,
				icon: "fas fa-bezier-curve",
				page: "via",
				bullet: "dot"
			},
			{
				title: "Estabelecimentos",
				root: true,
				icon: "fas fa-store-alt",
				page: "estabelecimento",
				bullet: "dot"
			},
			{
				title: "Administradores",
				root: true,
				icon: "fas fa-user-shield",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
		]
	}
};
